label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  font-weight: bold;
}

input {
  border: 1px solid black;
  width: 100%;
  height: 2rem;
  border-radius: 3rem;
}
